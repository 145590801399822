<template>
  <div id="jobDetail">
    <div class="mcontainer">
      <div class="lg:flex  lg:space-x-12">
        <div class="lg:w-3/4 card">
            <div class="h-44 md:h-60 overflow-hidden relative rounded-t-lg w-full" v-if="job_detail.banner !== ''">
                <img :src="job_detail.banner" alt="" class="w-full h-full absolute inset-0 object-cover">
            </div>
            <div class="md:p-7 p-4">
                <h2 class="text-2xl font-bold">{{ job_detail.title }}</h2>
                <p> PT TRANSPORTASI JAKARTA </p>
            </div>
            <hr class="border-gray-100">
            <div class="md:p-7 p-4">
                <h1 class="block text-xl font-bold mb-6"> Job details  </h1>
                <div class="md:flex">
                    <div class="md:w-3/4 space-y-4 -mt-2">
                        <!-- <div class="flex items-center space-x-3">
                            <Icon icon="ion:unlink" class="bg-gray-100 p-1.5 rounded-full text-xxl" />
                            <div class="flex-1">
                                <div>  <a href="#" class="text-blue-500"> Hargeisa Somaliland </a> </div>
                            </div>
                        </div> -->
                        <div class="flex items-center space-x-3">
                            <Icon icon="ion:mail-open" class="bg-gray-100 p-1.5 rounded-full text-xxl" />
                            <div class="flex-1">
                                <div> {{ job_detail.job_type }}  </div>
                            </div>
                        </div>
                        <div class="flex items-center space-x-3">
                            <Icon icon="ion:mail-open" class="bg-gray-100 p-1.5 rounded-full text-xxl" />
                            <div class="flex-1">
                                <div> Posted 21 hours ago </div>
                            </div>
                        </div>
                        <!-- <div class="flex items-center space-x-3">
                            <Icon icon="ion:unlink" class="bg-gray-100 p-1.5 rounded-full text-xxl" />
                            <div class="flex-1">
                                <div>  <a href="#" class="text-blue-500"> https://mydomain.com </a> </div>
                            </div>
                        </div>
                        <div class="flex items-center space-x-3">
                            <Icon icon="ion:albums" class="bg-gray-100 p-1.5 rounded-full text-xxl" />
                            <div class="flex-1">
                                <div>  <a href="#" class="text-blue-500">Product/service</a> </div>
                            </div>
                        </div> -->
                    </div>
                    <div class="flex-1 space-y-4 md:mt-0 mt-6">
                        <div>
                            <div class="text- font-semibold"> Minimum </div>
                            <div> Rp {{ job_detail.min_salary }} / Month</div>
                        </div>
                        <div>
                            <div  class="text- font-semibold"> Maximum </div>
                            <div> Rp {{ job_detail.max_salary }} / Month</div>
                        </div>
                        <a href="#" class="bg-blue-600 text-white py-2.5 text-center font-semibold w-full mt-4 block rounded"> Apply now </a>
                    </div>
                </div>
            </div>
            <hr>
            <div class="md:p-7 p-4">
                <h1 class="block text-xl font-bold mb-6"> Job Description  </h1>
                <div class="space-y-3">
                    {{ job_detail.description }}
                </div>
            </div>
        </div>
        <div class="lg:w-1/4 w-full flex-shrink-0 containerSticky">
          <vue-sticky-sidebar containerSelector=".containerSticky" innerWrapperSelector=".uk-sticky" :topSpacing='100'>
            <div class="card p-3 uk-sticky">
                <h2 class="text-xl font-semibold mb-2"> Recently Posted </h2>
                <ul class="-space-y-2">
                    <li>
                        <a href="#" class="hover:bg-gray-100 rounded-md p-3 -mx-3 block">
                            <h3 class="font-medium line-clamp-2">Technical Event Support and Producer Specialist</h3>
                            <div class="flex font-medium items-center mt-1 space-x-1.5 text-xs">
                                <div class="">Full-time</div>
                                <div class="pb-1"> . </div>
                                <div class="text-blue-500">100 - 200 / day</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="hover:bg-gray-100 rounded-md p-3 -mx-3 block">
                            <h3 class="font-medium line-clamp-2">IT Department Manager Blogger Entrepenour list</h3>
                            <div class="flex font-medium items-center mt-1 space-x-1.5 text-xs">
                                <div class="">Full-time</div>
                                <div class="pb-1"> . </div>
                                <div class="text-blue-500">100 - 200 / day</div>
                            </div>
                        </a>
                    </li>
                    <li>
                        <a href="#" class="hover:bg-gray-100 rounded-md p-3 -mx-3 block">
                            <h3 class="font-medium line-clamp-2">Hardware Verification Validation Profissional Engineer</h3>
                            <div class="flex font-medium items-center mt-1 space-x-1.5 text-xs">
                                <div class="">Full-time</div>
                                <div class="pb-1"> . </div>
                                <div class="text-blue-500">1900 - 2200 / day</div>
                            </div>
                        </a>
                    </li>
                </ul>
              <br>
              <h4 class="text-lg font-semibold mb-3"> Tags </h4>
              <div class="flex flex-wrap gap-2">
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Computers</a>
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Business</a>
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> News</a>
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Architecher</a>
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Technolgy</a>
                  <a href="#" class="bg-gray-100 py-1.5 px-4 rounded-full"> Music</a>
              </div>
            </div>
          </vue-sticky-sidebar>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
    import vueStickySidebar from 'vue-sticky-sidebar'
    import { Icon } from '@iconify/vue2'
    import axios from 'axios'

    export default {
        components: {
            vueStickySidebar,
            Icon
        },
        data () {
            return {
                job_detail: {}
            }
        },
        async created () {
            try {
                const res = await axios.get('job/' + this.$route.params.code)
                this.job_detail = res.data.result
            } catch (e) {
                console.error(e)
            }
        }
    }
</script>
